import {
  BoxProps,
  Center,
  Flex,
  Container,
  Stack,
  ButtonGroup,
  IconButton,
  Text
} from '@chakra-ui/react'
import { FaTwitter} from 'react-icons/fa'
import * as React from 'react'
import { Footer } from './Footer'
import { Main } from './Main'
import { Navbar } from './Navbar'
import { Logo } from './Logo'
import {useIntl} from "react-intl";

const Layout = (props: BoxProps) => {

  const intl = useIntl();

  return (
    <Flex direction="column" flex="1">
      <Navbar />
      <Main >
        {props.children}
      </Main>
      <Footer >
        <Container as="footer" bg={"white"} role="contentinfo" py={{ base: '12', md: '16' }}>
          <Stack spacing={{ base: '4', md: '5' }}>
            <Stack justify="space-between" direction="row" align="center">
              <Logo />
              <ButtonGroup variant="ghost">
                {/*<IconButton*/}
                {/*  as="a"*/}
                {/*  href="#"*/}
                {/*  aria-label="LinkedIn"*/}
                {/*  icon={<FaLinkedin fontSize="1.25rem" />}*/}
                {/*/>*/}
                {/*<IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />*/}
                <IconButton
                  as="a"
                  href="https://twitter.com/ikigaimail"
                  aria-label="Twitter"
                  icon={<FaTwitter fontSize="1.25rem" />}
                />
              </ButtonGroup>
            </Stack>
            <Text>{intl.formatMessage({id: "footer.craft"})} <span style={{ color: 'red' }}> ❤ </span> {intl.formatMessage({id: "footer.boston"})}</Text>
            <Text fontSize="sm" color="subtle">
              &copy; {new Date().getFullYear()} EPIPHYTE LLC, {intl.formatMessage({id: "footer.allrights"})}.
            </Text>
          </Stack>
        </Container>

      </Footer>
    </Flex>
  )
}

export default Layout;
