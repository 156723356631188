import {
  Box,
  BoxProps,
  Container,
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiMenu } from 'react-icons/fi'
import { Logo } from './Logo'
import {useIntl} from "react-intl";

export const Navbar = (props: BoxProps) => {

  const intl = useIntl();
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const signIn = () => {
    window.location.href="https://app.ikigaimail.com/login"
  }

  return (
    <Box as="nav" role="navigation">
      <Box as="section" pb={{ base: '0', md: '0' }}>
        <Box as="nav" bg="bg-surface" boxShadow={useColorModeValue('sm', 'sm-dark')}>
          <Container py={{ base: '4', lg: '5' }}>
            <HStack spacing="0" justify="space-between" >
              <Logo p={"0"} alignItems={"start"}/>
              {isDesktop ? (
                <Flex justify="space-between" flex="0">
                  {/*<ButtonGroup variant="link" spacing="8">*/}
                  {/*  {['Product', 'Pricing', 'Resources', 'Support'].map((item) => (*/}
                  {/*    <Button key={item}>{item}</Button>*/}
                  {/*  ))}*/}
                  {/*</ButtonGroup>*/}
                  <HStack spacing="3" >
                    <Button variant="ghost"
                            onClick={signIn}>
                      {intl.formatMessage({id: "header.signIn"})}
                    </Button>
                    <Button variant="primary">
                      {intl.formatMessage({id: "header.signUp"})}
                    </Button>
                  </HStack>
                </Flex>
              ) : (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    variant="ghost"
                    icon={<FiMenu fontSize="1.25rem" />}
                    aria-label="Open Menu"
                  />
                  <MenuList minWidth='240px'>
                    <MenuItemOption
                      onClick={signIn}
                      value='asc'>Sign In
                    </MenuItemOption>
                  </MenuList>
                </Menu>
              )}
            </HStack>
          </Container>
        </Box>
      </Box>
    </Box>
  )
}
