import * as React from "react"
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import {
  ChakraProvider,
  extendTheme
} from "@chakra-ui/react"
import { theme as proTheme } from '@chakra-ui/pro-theme'
import { Landing } from './Pages/Landing'
// import '@fontsource/clear-sans/all-400.css'
// import '@fontsource/clear-sans/all-700.css'
import "@fontsource/gothic-a1"

export const theme = extendTheme(
  {
    fonts: {
      heading: `'Gothic A1', sans-serif`,
      body: `'Gothic A1', sans-serif`,
    },
    colors: {
      // "gray": "#b8b6b9",
      "dark": "#0d000b",
      // ...proTheme.colors,
      brand: {
        "gray": "#b8b6b9",
        "50": "#fd29df",
        "100": "#fd0fdb",
        "200": "#f102cf",
        "300": "#d802b9",
        "400": "#bf01a3",
        "500": "#8c0178",
        "600": "#730162",
        "700": "#59014d",
        "800": "#400037",
        "900": "#270021"
      }
    },
  },
  proTheme,
)

export const App = () => (

    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Landing lang={"en-US"}/>} />
          <Route path={"/es-ES"} element={<Landing lang={"es-ES"}/>} />
          <Route path={"/ja-JA"} element={<Landing lang={"ja-JA"}/>} />
        </Routes>

      </BrowserRouter>
    </ChakraProvider>
)
