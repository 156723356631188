import axios from 'axios';
import React from 'react'
import {
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  useToast,
  useBreakpointValue,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@chakra-ui/react'
import {useIntl} from "react-intl";

export const JoinMailingListDrawer = () => {
  const [email, setEmail] = React.useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast();

  const intl = useIntl();

  const callLambda = async (email: string) => {


    const LAMBDA_FUNCTION_URL = "https://f5o55id7od6qomtarn6a62nefa0jbtaz.lambda-url.us-east-1.on.aws";
    const params = {
      email: email,
    }
    axios.post(LAMBDA_FUNCTION_URL, params)
      .then(res => {
        toast({
          title: "Success!",
          description: intl.formatMessage({id: "mailing.success"}),
          status: "success",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        })
        // Notification.Success(
        //   "Success!",
        //   "You have been added to the waiting list. We will contact you when Ikigai is ready for beta testing."
        // )
      })
      .catch(err => {
        console.log(err)
        console.log(err.toString())
        toast({
          title: "Error",
          description: err.toString(),
          status: "error",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        })
      })
  }

  const onSubmit = () => {
    console.log( email )
    callLambda(email).then(() => {
      onClose()
    })
  }

  return (
    <>
      <Button variant="primary" size={useBreakpointValue({ base: 'lg', md: 'xl' })} onClick={onOpen}>
        {intl.formatMessage({id: "mailing.button"})}
      </Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            {intl.formatMessage({id: "mailing.button"})}
          </DrawerHeader>
          <DrawerBody>
            <FormControl>
              <FormLabel>{intl.formatMessage({id: "mailing.emailAddress"})}</FormLabel>
              <Input
                type='email'
                onChange={event => setEmail(event.currentTarget.value)}
              />
              <FormHelperText>{intl.formatMessage({id: "mailing.emailShare"})}</FormHelperText>
            </FormControl>
          </DrawerBody>

          <DrawerFooter borderTopWidth='1px'>
            <Button variant='outline' mr={3} onClick={onClose}>
              {intl.formatMessage({id: "button.cancel"})}
            </Button>
            <Button
              variant={"primary"}
              onClick={onSubmit}
            >{intl.formatMessage({id: "button.submit"})}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
