import { Box, Button, Container, Heading, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import * as React from 'react'
import {JoinMailingListDrawer} from "./JoinMailingListDrawer";
import {useIntl} from "react-intl";

export const CTA = () => {

  const intl = useIntl();

  return (
    <Box as="section" bg="bg-surface">
      <Container py={{base: '16', md: '24'}}>
        <Stack spacing={{base: '8', md: '10'}}>
          <Stack spacing={{base: '4', md: '5'}} align="center">
            <Heading size={useBreakpointValue({base: 'sm', md: 'md'})}>
              {intl.formatMessage({id: "cta.title"})}
            </Heading>
            <Text color="muted" maxW="2xl" textAlign="center" fontSize="xl">
              {intl.formatMessage({id: "cta.subtitle"})}
            </Text>
          </Stack>
          <Stack spacing="3" direction={{base: 'column', sm: 'row'}} justify="center">
            <JoinMailingListDrawer/>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}
