import { BsKanban } from 'react-icons/bs'
import {FaMobileAlt, FaGoogle, FaFileAlt, FaKey} from 'react-icons/fa'
import { IoRocketSharp } from 'react-icons/io5'

export const features = [
  {
    name: "features.feature1.name",
    description: "features.feature1.description",
    icon: BsKanban,
  },
  {
    name: "features.feature2.name",
    description: "features.feature2.description",
    icon: IoRocketSharp,
  },
  {
    name: "features.feature3.name",
    description: "features.feature3.description",
    icon: FaKey,
  },
  {
    name: "features.feature4.name",
    description: "features.feature4.description",
    icon: FaGoogle,
  },
  {
    name: "features.feature5.name",
    description: "features.feature5.description",
    icon: FaFileAlt,
  },
  {
    name: "features.feature6.name",
    description:
      "features.feature6.description",
    icon: FaMobileAlt,
  },
]
