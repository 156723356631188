import {Hero} from "../Components/Hero";
import {Description} from "../Components/Description";
import {CTA} from "../Components/CTA";
import Layout from "../Components/Layout"
import * as React from "react";
import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl'
import {messages} from "../i18n/messages";

type Props = {
  lang: string
}

export const Landing = ({lang}: Props) => {



  return (
    // @ts-ignore
    <IntlProvider messages={messages[lang]} locale={lang} defaultLocale="en">
      <Layout>
        <Hero/>
        <Description/>
        <CTA/>
      </Layout>
    </IntlProvider>
  )
}
